import Translation from "@/components/Traslations";
import {Fragment} from "react";
import {Link} from "react-router-dom";

const HeaderJustLogo = () => {
    return (
        <Fragment>
            <div className="flex items-end text-right container w-screen justify-end text-lg px-5 pt-5 z-50">
                <div className={"w-1"}></div>
                <Link to={"/"} className={"m-auto text-center justify-center text-md"}>
                    Hardihooder Angel Society
                </Link>
                <Translation/>
            </div>
        </Fragment>
    )
};

export default HeaderJustLogo;
