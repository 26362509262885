import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

// const storedLang = store.get('wanted.language') || 'EN'

const Translation: React.FC = () =>{
    const checkLang = (e : string) => {
        return e === 'en' ? 'ko' : 'en'
    }

    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState(checkLang(i18n.language).toUpperCase())

    useEffect(() => {

    }, [lang])

    const handleLangClick = () => {
        const l_lang = lang.toLowerCase();
        setLang(checkLang(l_lang).toUpperCase());
        i18n.changeLanguage(l_lang, (err, t) => {
            t('key'); // -> same as i18next.t
        });

    }

    return (
        <div key={"translate-23"}>
            <button onClick={handleLangClick}>{lang}</button>
        </div>
    )
}

export default Translation;