import {lazy} from "react";
import {BrowserRouter, createBrowserRouter, Route, Routes} from 'react-router-dom';
import MintPage from "../pages/MintPage";
import MainPage from "../pages/MainPage";
import Layout from "./layout/Layout";
import TitleLogoLayout from "@/components/layout/TitleLogoLayout";
import AboutPage from "@/pages/AboutPage";
import ToSPage from "@/pages/ToSPage";
import TitleLogoNoLngLayout from "@/components/layout/TitleLogoNoLngLayout";
import PPPage from "@/pages/PPPage";
import TitleLogoNoLngStickyFooterLayout from "@/components/layout/TitleLogoNoLngStickyFooterLayout";
import NotFound from "@/pages/NotFound";

const RouterComponent = () => {
    return createBrowserRouter([
        {
            path: '/',
            element: <Layout/>,
            children: [
                { index: true, element: <MainPage /> },
            ],
        },
        {
            path: '/',
            element: <TitleLogoLayout/>,
            children: [
                { path: "about", element: <AboutPage /> },
            ],
        },
        {
            path: '/',
            element: <TitleLogoNoLngStickyFooterLayout/>,
            children: [
                { path: "jointest", element: <MintPage /> },
            ],
        },
        {
            path: '/terms/',
            element: <TitleLogoNoLngLayout/>,
            children: [
                { path: "termsofuse", element: <ToSPage /> },
                { path: "privacypolicy", element: <PPPage /> },
            ],

        },
        {
            path: '*',
            element: <NotFound />
        }
    ]);
}

export default RouterComponent;
