import { stagger, motion, animate, useAnimate} from "framer-motion";
import {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";

const About = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
            className="container max-w-6xl mx-auto flex flex-col items-center gap-y-10 bg-white bg-opacity-25 px-10 py-10 mb-16">
            <div className="flex justify-center items-center px-7">
                <img src="/images/about_background.png" alt="about_background"/>
            </div>
            <div className="flex flex-col gap-y-3 text-gray-900 justify-center">
                <div className="mt-50 text-5xl text-gray-900 text-center whitespace-pre-wrap mb-3">
                    {t('description_title')}
                </div>
                <div className="text-xl text-gray-900 text-center whitespace-pre-wrap mb-8">
                    {t('description_subtitle')}
                </div>
                <hr/>
                <motion.ul
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    {
                        t('description_content').split('\n').map((line, index) => (
                            <li key={`desc_con_${index}`} className="font-pretendard text-sm leading-10 text-gray-900 text-center whitespace-pre-wrap mt-4">
                                {line}
                            </li>
                        ))
                    }
                </motion.ul>
            </div>
        </motion.div>
    );
};


export default About;
