/** Change these values to configure the application for your own use. **/
import {Ethereum, Sepolia} from '@thirdweb-dev/chains';

// Your smart contract address (available on the thirdweb dashboard)
// For existing collections: import your existing contracts on the dashboard: https://thirdweb.com/dashboard
// TODO: thirdweb contract address
export const contractConst =  "0x5E4DB1700ba9329556f7474E82713F4BaDD1c9f2" //"0x977E5d8a609B3305D3c672BE68Fb207da32C9C98";

// The name of the chain your contract is deployed to.
// Refer to README.md on how to specify the chain name.
// TODO: ether testnet sepolia
export const chainConst = Sepolia// Ethereum;

// It is IMPORTANT to provide your own API key to use the thirdweb SDK and infrastructure.
// Please ensure that you define the correct domain for your API key from the API settings page.
// You can get one for free at https://thirdweb.com/create-api-key
// Learn more here: https://blog.thirdweb.com/changelog/api-keys-to-access-thirdweb-infra

// TODO: thirdweb cliet id
export const clientIdConst = import.meta.env.VITE_TEMPLATE_CLIENT_ID || "";

// Configure the primary color for buttons and other UI elements
export const primaryColorConst = "blue";

// Choose between "light" and "dark" mode
export const themeConst = "white";

// Gasless relayer configuration options
export const relayerUrlConst = ""; // OpenZeppelin relayer URL
export const biconomyApiKeyConst = ""; // Biconomy API key
export const biconomyApiIdConst = ""; // Biconomy API ID
