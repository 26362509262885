import React from 'react';
import { RouterProvider, } from 'react-router-dom';
import RouterComponent from './components/routers';

const App: React.FC = () => {
  return (
      <RouterProvider router={RouterComponent()}></RouterProvider>
  );
};

export default App;