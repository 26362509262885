import { Outlet } from 'react-router-dom';
import Footer from "@/components/Footer";
import { Fragment } from 'react/jsx-runtime';
import HeaderJustLogo from "@/components/HeaderJustLogo";

const TitleLogoLayout = () => (
    <Fragment>
        <HeaderJustLogo />
        <Outlet />
        <Footer/>
    </Fragment>
);

export default TitleLogoLayout;