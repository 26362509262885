import React, { lazy} from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {
    coinbaseWallet,
    metamaskWallet,
    okxWallet,
    phantomWallet,
    ThirdwebProvider, trustWallet,
    walletConnect, zerionWallet
} from "@thirdweb-dev/react";
import "./styles/globals.css";

import {getGasless} from "./utils/getGasless";
import './lang/i18n'

const Toaster = lazy(() => import('@/components/ui/Toaster'));

import {
    biconomyApiIdConst,
    biconomyApiKeyConst,
    chainConst,
    relayerUrlConst,
    clientIdConst,
} from "./consts/parameters";

const container = document.getElementById("root");
const root = createRoot(container!);
const urlParams = new URL(window.location.toString()).searchParams;

const relayerUrl = urlParams.get("relayUrl") || relayerUrlConst || "";
const biconomyApiKey =
    urlParams.get("biconomyApiKey") || biconomyApiKeyConst || "";
const biconomyApiId =
    urlParams.get("biconomyApiId") || biconomyApiIdConst || "";
const sdkOptions = getGasless(relayerUrl, biconomyApiKey, biconomyApiId);

const chain = (urlParams.get("chain") && urlParams.get("chain")?.startsWith("{")) ? JSON.parse(String(urlParams.get("chain"))) : urlParams.get("chain") || chainConst;

const clientId = urlParams.get("clientId") || clientIdConst || "";

root.render(
    <React.StrictMode>
        <ThirdwebProvider
            activeChain={chain}
            sdkOptions={sdkOptions}
            clientId={clientId}
            supportedWallets={[
                okxWallet({recommended: false}),
                metamaskWallet({recommended: false}),
                coinbaseWallet({recommended: false}),
                walletConnect({recommended: false}),
                phantomWallet({recommended: false}),
                trustWallet({recommended: false}),
                zerionWallet({recommended: false})
            ]}
        >
            <Toaster/>
            <App/>
        </ThirdwebProvider>
    </React.StrictMode>,
);
