import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import translationEn from './translation.en.json';
import translationKo from './translation.ko.json';

export const supportedLngs = {
    en: "English",
    ko: "한국어",
};

const resources = {
    en: {
        translation: translationEn
    },
    ko: {
        translation: translationKo
    }
};

export const languages = [ 'en', 'ko' ] as const;

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        supportedLngs: Object.keys(supportedLngs),
        allowObjectInHTMLChildren: true, // html 요소가 객체를 받을수 있도록 한다. 객체를 html 요소로 전달해서 각각의 보간으로 대체할 수 있다. 값(대부분 Trans 성분 포함)
        react: {
            useSuspense: true,
        },
    } as any);

export default i18n;
