import { Outlet } from 'react-router-dom';
import Footer from "@/components/Footer";
import { Fragment } from 'react/jsx-runtime';
import HeaderJustLogoNoLng from "@/components/HeaderJustLogoNoLng";

const TitleLogoLayout = () => (
    <Fragment>
        <HeaderJustLogoNoLng />
        <Outlet />
        <Footer/>
    </Fragment>
);

export default TitleLogoLayout;