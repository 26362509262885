import {Fragment} from "react";

const ToS = () => {
    return (
        <div className="font-pretendard flex flex-col items-center justify-center py-8">
            <div className="w-full max-w-4xl p-8 bg-white rounded-lg">
                <h1 className="mb-6 text-3xl font-bold text-center text-gray-800">
                    Terms of Use
                </h1>
                <p className="mb-4 text-gray-600">
                    Last Updated: <span className="font-semibold">08.17.2024</span>
                </p>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        1. Eligibility
                    </h2>
                    <p className="text-gray-600">
                        You must be at least 18 years old or the age of majority in your
                        jurisdiction to access or use our Service. By using the Service, you
                        represent and warrant that you meet these requirements.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        2. NFT Ownership
                    </h2>
                    <p className="text-gray-600">
                        When you purchase an NFT through our Service, you own the digital
                        asset associated with that NFT. Ownership of the NFT is mediated
                        entirely by the smart contract and the blockchain network. We do not
                        store or manage your NFTs.
                    </p>
                    <p className="mt-2 text-gray-600">
                        Ownership of an NFT does not grant you ownership of the underlying
                        intellectual property rights associated with the content represented
                        by the NFT. Unless explicitly stated, all intellectual property
                        rights in the content remain with the original creators or
                        licensors.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        3. License to Use
                    </h2>
                    <p className="text-gray-600">
                        Subject to your compliance with these Terms, we grant you a limited,
                        worldwide, non-exclusive, non-transferable, royalty-free license to
                        display the artwork or content associated with the NFT solely for
                        your own personal, non-commercial use.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        4. Prohibited Activities
                    </h2>
                    <p className="text-gray-600">
                        You agree not to engage in any of the following activities:
                        <ul className="mt-2 ml-4 list-disc">
                            <li>Engaging in any activity that violates any applicable law or regulation;</li>
                            <li>Attempting to disrupt or interfere with the Service or its functionality;</li>
                            <li>Using the Service for any unauthorized or fraudulent purposes;</li>
                            <li>Modifying or creating derivative works of any content associated with the NFTs without
                                permission.
                            </li>
                        </ul>
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        5. Payments and Fees
                    </h2>
                    <p className="text-gray-600">
                        All transactions, including purchasing, selling, and transferring
                        NFTs, are conducted through the blockchain network. We have no
                        control over the blockchain network and are not responsible for any
                        issues that may arise during these transactions. You are responsible
                        for any gas fees or other charges associated with your transactions
                        on the blockchain.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        6. Assumption of Risk
                    </h2>
                    <p className="text-gray-600">
                        You understand and agree that your use of the Service involves
                        certain risks, including price volatility of NFTs and
                        cryptocurrencies, risk of losing access to your NFTs due to loss of
                        private keys, custodial errors, or buyer/seller errors, and risk of
                        unauthorized third-party activities, such as hacking or phishing.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        7. Disclaimers and Limitation of Liability
                    </h2>
                    <p className="text-gray-600">
                        The Service is provided "as is" and "as available" without any
                        warranties of any kind, either express or implied. We do not
                        guarantee the accuracy, completeness, or availability of the
                        Service.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        8. Indemnification
                    </h2>
                    <p className="text-gray-600">
                        You agree to indemnify, defend, and hold harmless{" "}
                        <span className="font-semibold">HardihooderAngelSociety</span> and
                        its affiliates, officers, directors, employees, and agents from and
                        against any and all claims, damages, liabilities, losses, or
                        expenses arising out of or in any way connected with your use of the
                        Service or your violation of these Terms.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        9. Termination
                    </h2>
                    <p className="text-gray-600">
                        We reserve the right to terminate or suspend your access to the
                        Service at our sole discretion, without notice or liability, for any
                        reason, including if you breach these Terms.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        10. Governing Law and Dispute Resolution
                    </h2>
                    <p className="text-gray-600">
                        These Terms shall be governed by and construed in accordance with
                        the laws of <span className="font-semibold">[Jurisdiction]</span>.
                        Any disputes arising out of or in connection with these Terms shall
                        be resolved through binding arbitration in{" "}
                        <span className="font-semibold">[Jurisdiction]</span>.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        11. Changes to the Terms
                    </h2>
                    <p className="text-gray-600">
                        We may update these Terms from time to time. If we make material
                        changes, we will notify you by updating the "Last Updated" date at
                        the top of these Terms. Your continued use of the Service after any
                        such changes constitutes your acceptance of the new Terms.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">
                        12. Contact Us
                    </h2>
                    <p className="text-gray-600">
                        If you have any questions about these Terms, please contact us at{" "}
                        <span className="font-semibold">
                            <a href="mailto:hasssspace@gmail.com"> hasssspace@gmail.com</a>
                        </span>.
                    </p>
                </section>
            </div>
        </div>
    );
};


export default (ToS);
