import {
    detectContractFeature,
    useActiveClaimConditionForWallet,
    useAddress,
    useClaimConditions,
    useClaimedNFTSupply,
    useClaimerProofs,
    useClaimIneligibilityReasons,
    useContract,
    useContractMetadata,
    useNFT,
    useUnclaimedNFTSupply,
    Web3Button,
} from "@thirdweb-dev/react";
import {BigNumber, utils} from "ethers";
import React, {useEffect, useMemo, useState} from "react";
import {HeadingImage} from "@/components/HeadingImage";
import {parseIneligibility} from "@/utils/parseIneligibility";
import {
    clientIdConst,
    contractConst,
    primaryColorConst,
    themeConst,
} from "@/consts/parameters";

const urlParams = new URL(window.location.toString()).searchParams;
const contractAddress = urlParams.get("contract") || contractConst || "";
const primaryColor =
    urlParams.get("primaryColor") || primaryColorConst || undefined;

const colors = {
    purple: "#7C3AED",
    blue: "#3B82F6",
    orange: "#F59E0B",
    pink: "#EC4899",
    green: "#10B981",
    red: "#EF4444",
    teal: "#14B8A6",
    cyan: "#22D3EE",
    yellow: "#FBBF24",
} as const;

const MintPage: React.FC = () => {
    const contractQuery = useContract(contractAddress);
    const contractMetadata = useContractMetadata(contractQuery.contract);
    let theme = "light";
    // let theme = (urlParams.get("theme") || themeConst || "light") as
    //     | "light"
    //     | "dark"
    //     | "system";
    // if (theme === "system") {
    //     theme = window.matchMedia("(prefers-color-scheme: dark)").matches
    //         ? "dark"
    //         : "light";
    // }
    const root = window.document.documentElement;
    root.classList.add(theme);
    const address = useAddress();
    const [quantity, setQuantity] = useState(1);
    const claimConditions = useClaimConditions(contractQuery.contract);
    const activeClaimCondition = useActiveClaimConditionForWallet(
        contractQuery.contract,
        address,
    );
    const claimerProofs = useClaimerProofs(contractQuery.contract, address || "");
    const claimIneligibilityReasons = useClaimIneligibilityReasons(
        contractQuery.contract,
        {
            quantity,
            walletAddress: address || "",
        },
    );
    const unclaimedSupply = useUnclaimedNFTSupply(contractQuery.contract);
    const claimedSupply = useClaimedNFTSupply(contractQuery.contract);
    const {data: firstNft, isLoading: firstNftLoading} = useNFT(
        contractQuery.contract,
        0,
    );

    const numberClaimed = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0).toString();
    }, [claimedSupply]);

    const numberTotal = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0)
            .add(BigNumber.from(unclaimedSupply.data || 0))
            .toString();
    }, [claimedSupply.data, unclaimedSupply.data]);


    const priceToMint = useMemo(() => {
        const bnPrice = BigNumber.from(
            activeClaimCondition.data?.currencyMetadata.value || 0,
        );
        return `${utils.formatUnits(
            bnPrice.mul(quantity).toString(),
            activeClaimCondition.data?.currencyMetadata.decimals || 18,
        )} ${activeClaimCondition.data?.currencyMetadata.symbol}`;
    }, [
        activeClaimCondition.data?.currencyMetadata.decimals,
        activeClaimCondition.data?.currencyMetadata.symbol,
        activeClaimCondition.data?.currencyMetadata.value,
        quantity,
    ]);

    const isOpenEdition = useMemo(() => {
        if (contractQuery?.contract) {
            const contractWrapper = (contractQuery.contract as any).contractWrapper;

            const featureDetected = detectContractFeature(
                contractWrapper,
                "ERC721SharedMetadata",
            );

            return featureDetected;
        }
        return false;
    }, [contractQuery.contract]);

    const maxClaimable = useMemo(() => {
        let bnMaxClaimable;
        try {
            bnMaxClaimable = BigNumber.from(
                activeClaimCondition.data?.maxClaimableSupply || 0,
            );
        } catch (e) {
            bnMaxClaimable = BigNumber.from(1_000_000);
        }

        let perTransactionClaimable;
        try {
            perTransactionClaimable = BigNumber.from(
                activeClaimCondition.data?.maxClaimablePerWallet || 0,
            );
        } catch (e) {
            perTransactionClaimable = BigNumber.from(1_000_000);
        }

        if (perTransactionClaimable.lte(bnMaxClaimable)) {
            bnMaxClaimable = perTransactionClaimable;
        }

        const snapshotClaimable = claimerProofs.data?.maxClaimable;

        if (snapshotClaimable) {
            if (snapshotClaimable === "0") {
                // allowed unlimited for the snapshot
                bnMaxClaimable = BigNumber.from(1_000_000);
            } else {
                try {
                    bnMaxClaimable = BigNumber.from(snapshotClaimable);
                } catch (e) {
                    // fall back to default case
                }
            }
        }

        const maxAvailable = BigNumber.from(unclaimedSupply.data || 0);

        let max;
        if (maxAvailable.lt(bnMaxClaimable) && !isOpenEdition) {
            max = maxAvailable;
        } else {
            max = bnMaxClaimable;
        }

        if (max.gte(1_000_000)) {
            return 1_000_000;
        }
        return max.toNumber();
    }, [
        claimerProofs.data?.maxClaimable,
        unclaimedSupply.data,
        activeClaimCondition.data?.maxClaimableSupply,
        activeClaimCondition.data?.maxClaimablePerWallet,
    ]);

    const isSoldOut = useMemo(() => {
        try {
            return (
                (activeClaimCondition.isSuccess &&
                    BigNumber.from(activeClaimCondition.data?.availableSupply || 0).lte(
                        0,
                    )) ||
                (numberClaimed === numberTotal && !isOpenEdition)
            );
        } catch (e) {
            return false;
        }
    }, [
        activeClaimCondition.data?.availableSupply,
        activeClaimCondition.isSuccess,
        numberClaimed,
        numberTotal,
        isOpenEdition,
    ]);

    const canClaim = useMemo(() => {
        return (
            activeClaimCondition.isSuccess &&
            claimIneligibilityReasons.isSuccess &&
            claimIneligibilityReasons.data?.length === 0 &&
            !isSoldOut
        );
    }, [
        activeClaimCondition.isSuccess,
        claimIneligibilityReasons.data?.length,
        claimIneligibilityReasons.isSuccess,
        isSoldOut,
    ]);

    const isLoading = useMemo(() => {
        return (
            activeClaimCondition.isLoading ||
            unclaimedSupply.isLoading ||
            claimedSupply.isLoading ||
            !contractQuery.contract
        );
    }, [
        activeClaimCondition.isLoading,
        contractQuery.contract,
        claimedSupply.isLoading,
        unclaimedSupply.isLoading,
    ]);

    const buttonLoading = useMemo(
        () => isLoading || claimIneligibilityReasons.isLoading,
        [claimIneligibilityReasons.isLoading, isLoading],
    );

    const buttonText = useMemo(() => {
        if (isSoldOut) {
            return "Sold Out";
        }

        if (canClaim) {
            const pricePerToken = BigNumber.from(
                activeClaimCondition.data?.currencyMetadata.value || 0,
            );
            if (pricePerToken.eq(0)) {
                return "Mint (Free)";
            }
            return `Mint (${priceToMint})`;
        }

        // TODO:// 나중엔 Mint
        return `Claim First`
        // if (claimIneligibilityReasons.data?.length) {
        //     try{
        //         if (claimIneligibilityReasons.data[0] == 'Cannot claim more than maximum allowed quantity.'){
        //             // toast({
        //             //     title: "Cannot claim more than maximum allowed quantity.",
        //             //     description: "",
        //             //     duration: 9000,
        //             //     variant: "destructive",
        //             // });
        //
        //             return 'Max limit exceeded.';
        //         }
        //     }catch{
        //
        //     }
        //
        //     // return parseIneligibility(claimIneligibilityReasons.data, quantity)
        //     return ''
        // }
        // if (buttonLoading) {
        //     return "Checking eligibility...";
        // }
        //
        // return "Minting not available";
    }, [
        isSoldOut,
        canClaim,
        claimIneligibilityReasons.data,
        buttonLoading,
        activeClaimCondition.data?.currencyMetadata.value,
        priceToMint,
        quantity,
    ]);

    const dropNotReady = useMemo(
        () =>
            claimConditions.data?.length === 0 ||
            claimConditions.data?.every((cc) => cc.maxClaimableSupply === "0"),
        [claimConditions.data],
    );

    const dropStartingSoon = useMemo(
        () =>
            (claimConditions.data &&
                claimConditions.data.length > 0 &&
                activeClaimCondition.isError) ||
            (activeClaimCondition.data &&
                activeClaimCondition.data.startTime > new Date()),
        [
            activeClaimCondition.data,
            activeClaimCondition.isError,
            claimConditions.data,
        ],
    );

    const clientId = urlParams.get("clientId") || clientIdConst || "";
    if (!clientId) {
        return (
            <div className="flex items-center justify-center h-full">
                Client ID is required as a query param to use this page.
            </div>
        );
    }

    if (!contractAddress) {
        return (
            <div className="flex items-center justify-center h-full">
                No contract address provided
            </div>
        );
    }

    return (
        <div className="w-screen justify-center">
            <div className="grid">
                <div className="flex items-center justify-center w-full col-span-1 lg:col-span-7">
                    <div className="flex flex-col w-full p-12 max-w-80">
                        <div>
                            <div className="flex items-center justify-center">
                                <img src="/images/has_logo.png" alt="logo"/>
                            </div>
                        </div>

                        <h5 className="mt-5 text-lg line-clamp-1 xs:text-md lg:text-lg text-center">Price
                            Per NFT </h5>
                        <h5 className="text-xl font-bold line-clamp-1 xs:text-lg lg:text-xl text-center">0.0777
                            ETH Each</h5>
                        <h5 className="text-sm font-bold line-clamp-1 xs:text-sm lg:text-xs text-center">4 Max</h5>

                        {/* 777 Left */}
                        <div className="mt-4 flex flex-col gap-1 xs:gap-1 justify-center item-center text-center">
                            {isLoading ? (
                                <div
                                    role="status"
                                    className="space-y-8 animate-pulse md:flex md:items-center md:space-x-8 md:space-y-0"
                                >
                                    <div className="w-full flex justify-center">
                                        <div className="w-24 h-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                    </div>
                                </div>
                            ) : isOpenEdition ? null : (
                                <p className={"mt-4"}>
                                    <span className="text-3xl font-900 tracking-wider xs:text-2xl lg:text-3xl">
                                        {`${Number(numberTotal) - Number(numberClaimed)}`}
                                    </span>{" left"}
                                </p>
                            )}
                        </div>
                        {/* - 1 + */}
                        <div className="mt-4 flex w-full gap-4 justify-center">
                            <div className="mt-4 flex flex-col w-full gap-2 lg:flex-col lg:items-center lg:gap-4">
                                <div className="flex px-30 w-full h-11 md:w-full  justify-center">
                                    <button
                                        onClick={() => {
                                            const value = quantity - 1;
                                            if (value > maxClaimable) {
                                                setQuantity(maxClaimable);
                                            } else if (value < 1) {
                                                setQuantity(1);
                                            } else {
                                                setQuantity(value);
                                            }
                                        }}
                                        className="flex items-center w-8 justify-center h-full px-2 text-2xl text-center rounded-l-md disabled:cursor-not-allowed disabled:text-gray-500 dark:text-white dark:disabled:text-gray-600"
                                        disabled={isSoldOut || quantity - 1 < 1}
                                    >
                                        -
                                    </button>
                                    <p
                                        className="flex items-center justify-center w-full h-full font-mono text-center dark:text-white lg:w-full">
                                        {/*className="flex items-center justify-center h-full font-mono text-center dark:text-white">*/}
                                        {!isLoading && isSoldOut ? "Sold Out" : `${quantity}`}
                                    </p>
                                    <button
                                        onClick={() => {
                                            const value = quantity + 1;
                                            if (value > maxClaimable) {
                                                setQuantity(maxClaimable);
                                            } else if (value < 1) {
                                                setQuantity(1);
                                            } else {
                                                setQuantity(value);
                                            }
                                        }}
                                        className={"flex h-full items-center justify-center rounded-r-md px-2 text-center text-2xl " +
                                            "disabled:cursor-not-allowed disabled:text-gray-500 dark:text-white dark:disabled:text-gray-600"}
                                        disabled={isSoldOut || quantity + 1 > maxClaimable}
                                    >
                                        +
                                    </button>
                                </div>
                                <Web3Button
                                    contractAddress={
                                        contractQuery.contract?.getAddress() || ""
                                    }
                                    style={{
                                        maxHeight: "43px",
                                    }}
                                    theme={"light"}
                                    className={"connect-wallet"}
                                    action={(cntr) => cntr.erc721.claim(quantity)}
                                    isDisabled={!canClaim || buttonLoading}
                                    onError={(err) => {
                                        alert("Failed to mint drop");
                                    }}
                                    onSuccess={() => {
                                        alert("The NFT has been transferred to your wallet");
                                    }}
                                >
                                    {buttonLoading ? (
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="w-4 h-4 text-gray-200 animate-spin fill-blue-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        buttonText
                                    )}
                                </Web3Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MintPage;
