import {Fragment} from "react";

const PP = () => {
    return (
        <div className="font-pretendard flex flex-col items-center justify-center py-8">
            <div className="w-full max-w-4xl p-8 bg-white rounded-lg">
                <h1 className="mb-6 text-3xl font-bold text-center text-gray-800">Privacy Policy</h1>
                <p className="mb-4 text-gray-600">Last Updated: <span className="font-semibold">08.17.2024</span></p>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">1. Introduction</h2>
                    <p className="text-gray-600">
                        Welcome to HardihooderAngelSociety. This Privacy Policy explains how we collect, use, disclose,
                        and safeguard your information when you visit our website <a href={"https://hardihooderangelsociety.com"}>hardihooderangelsociety.com</a>.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">2. Information We Collect</h2>
                    <p className="text-gray-600">
                        We may collect information about you in a variety of ways. The information we may collect on the
                        Site includes:
                    </p>
                    <ul className="mt-2 ml-4 list-disc text-gray-600">
                        <li><strong>Personal Data:</strong> Personally identifiable information, such as your name,
                            shipping address, email address, and telephone number.
                        </li>
                        <li><strong>Financial Data:</strong> Financial information, such as data related to your payment
                            method.
                        </li>
                        <li><strong>Blockchain Data:</strong> Information about your cryptocurrency wallet and
                            transactions.
                        </li>
                        <li><strong>Derivatives Data:</strong> Information our servers automatically collect, such as
                            your IP address and browser type.
                        </li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">3. Use of Your Information</h2>
                    <p className="text-gray-600">
                        Having accurate information about you permits us to provide you with a smooth, efficient, and
                        customized experience. Specifically, we may use information collected about you via the Site to:
                    </p>
                    <ul className="mt-2 ml-4 list-disc text-gray-600">
                        <li>Create and manage your account.</li>
                        <li>Process your transactions and send you related information.</li>
                        <li>Communicate with you regarding updates and promotions.</li>
                        <li>Monitor and analyze usage and trends to improve your experience.</li>
                        <li>Fulfill and manage purchases, orders, and other transactions.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">4. Disclosure of Your Information</h2>
                    <p className="text-gray-600">
                        We may share information we have collected about you in certain situations, such as:
                    </p>
                    <ul className="mt-2 ml-4 list-disc text-gray-600">
                        <li><strong>By Law or to Protect Rights:</strong> If required to comply with legal obligations
                            or protect our rights.
                        </li>
                        <li><strong>Third-Party Service Providers:</strong> We may share your information with service
                            providers that assist us with operations.
                        </li>
                        <li><strong>Business Transfers:</strong> In the event of a business transaction, your
                            information may be transferred as part of that deal.
                        </li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">5. Security of Your Information</h2>
                    <p className="text-gray-600">
                        We use various security measures to protect your personal information, but please be aware that
                        no security is perfect.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">6. Your Rights</h2>
                    <p className="text-gray-600">
                        Depending on your location, you may have rights regarding your personal information, including
                        the right to access, correct, or delete your data.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">7. Use of Cookies</h2>
                    <p className="text-gray-600">
                        We may use cookies and other tracking technologies to collect information about how you interact
                        with our Site.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">8. Changes to this Privacy Policy</h2>
                    <p className="text-gray-600">
                        We may update this Privacy Policy from time to time. Any changes will be reflected by the "Last
                        Updated" date at the top.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="mb-2 text-2xl font-semibold text-gray-700">9. Contact Us</h2>
                    <p className="text-gray-600">
                        If you have any questions about this Privacy Policy, please contact us at
                        <span className="font-semibold">
                            <a href="mailto:hasssspace@gmail.com"> hasssspace@gmail.com</a>
                        </span>.
                    </p>
                </section>
            </div>
        </div>
    );
};


export default (PP);
