import { useTranslation } from 'react-i18next';
import {Link, Outlet, useLocation} from "react-router-dom";
import React from "react";
import HeaderJustLogoNoLng from "@/components/HeaderJustLogoNoLng";
import StickyFooter from "@/components/StickyFooter";

const NotFound = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <div>
            <HeaderJustLogoNoLng/>
            <div className="w-screen justify-center px-36 items-center">
                <div
                    className="container max-w-6xl mx-auto flex flex-col items-center gap-y-10 bg-white bg-opacity-25 px-10">
                    <div className="flex justify-center items-center mt-5">
                        <img src="/images/has_logo_crop.png" className={"lg:w-2/6 md:w-2/6 xs:w-11/12"}
                             alt="about_background"/>
                    </div>
                    <div className="flex flex-col gap-y-6 text-gray-900 justify-center">
                        <p className="text-5xl w-full text-semibold text-center uppercase">
                            {/*Hardihooder<br/>Angel<br/>Society*/}
                            not found
                        </p>
                        <div
                            className="mt-50 font-pretendard text-md text-bold text-gray-900 text-center whitespace-pre-wrap">
                            Double-check the link or Check @HAngelSociety Twitter.
                        </div>
                    </div>
                    <div className={"flex flex-row gap-x-2"}>
                        <Link to={"/"}
                              className="text-bold text-lg text-black font-bold py-1 px-6 text-center border-2 border-neutral-600  hover:border-neutral-400 hover:text-neutral-400">
                            Back to Homepage
                        </Link>
                    </div>
                </div>
            </div>
            <StickyFooter/>
        </div>
    )
};


export default (NotFound);