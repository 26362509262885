import {NavDesktop} from "./header/NavDesktop";
import {NavMobile} from "./header/NavMobile";
import { BrowserView, MobileView } from 'react-device-detect'
import Translation from "@/components/Traslations";
import {Fragment} from "react";
import {Link} from "react-router-dom";

const HeaderJustLogoNoLng = () => {
    return (
        <Fragment>
            <div className="flex items-end text-right container w-screen justify-end text-lg px-5 pt-5 z-50">
                <Link to={"/"} className={"m-auto text-center justify-center text-md"}>
                    Hardihooder Angel Society
                </Link>
            </div>
        </Fragment>
    )
};

export default HeaderJustLogoNoLng;
