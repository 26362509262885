import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXTwitter, faDiscord, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faShield } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect'

import React, {Fragment} from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <Fragment>
            <BrowserView>
                <motion.footer
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                    variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
                    style={{marginTop: '6rem'}}
                    className="flex flex-col pb-1 sm:flex-row w-full shrink-0 items-center px-4 md:px-6 bg-white">
                    <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 HardihooderAngelSociety. All rights
                        reserved.</p>
                    <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                        <Link to={"/terms/termsofuse"} className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400">
                            Terms of use
                        </Link>
                        <Link to={"/terms/privacypolicy"} className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400">
                            Privacy policy
                        </Link>
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="mailto:hasssspace@gmail.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faEnvelope}/>
                        </a>
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="https://www.instagram.com/hardihooder_angel_society/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faInstagram}/>
                        </a>
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="https://twitter.com/HAngelSociety" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faTwitter}/>
                        </a>
                    </nav>
                </motion.footer>
            </BrowserView>
            <MobileView>
                <motion.footer
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                    variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
                    style={{marginTop: '6.5rem'}}
                    className="flex flex-col pb-1 sm:flex-row w-full shrink-0 items-center px-4 md:px-6 bg-white">
                    <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 HardihooderAngelSociety. All rights
                        reserved.</p>
                    <nav className="sm:ml-auto flex flex-row gap-4 sm:gap-6 mt-3">
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="mailto:hasssspace@gmail.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faEnvelope}/>
                        </a>
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="https://www.instagram.com/hardihooder_angel_society/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faInstagram}/>
                        </a>
                        <a className="text-xs hover:underline underline-offset-4 text-gray-500 dark:text-gray-400"
                           href="https://x.com/HAngelSociety" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="w-5 h-5" style={{color: 'gray'}} icon={faXTwitter}/>
                        </a>
                    </nav>
                </motion.footer>
            </MobileView>
        </Fragment>
)
}

export default Footer;