import {AnimatePresence, motion} from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

const Main = () => {
    const { t, i18n } = useTranslation();

    return (
        <AnimatePresence>
            <div className="container max-w-6xl mx-auto flex flex-col items-center gap-y-10 bg-white bg-opacity-25 px-10">
                <div className="flex justify-center items-center mt-5">
                    <img src="/images/has_logo_crop.png" className={"lg:w-2/6 md:w-2/6 xs:w-11/12"} alt="about_background"/>
                </div>
                <div className="flex flex-col gap-y-6 text-gray-900 justify-center">
                    <p className="text-5xl w-full text-semibold text-center uppercase">
                        Hardihooder<br/>Angel<br/>Society
                    </p>
                    <div className="mt-50 font-pretendard text-md text-bold text-gray-900 text-center whitespace-pre-wrap">
                        {t('about_content')}
                    </div>
                </div>
                <div className={"flex flex-row gap-x-2"}>
                    <Link to={"/about"} className="text-lg text-black w-36 py-1 px-6 text-center border-2 border-neutral-600  hover:border-neutral-400 hover:text-neutral-400">
                        About Us
                    </Link>
                    <div className="bg-black text-lg text-white w-36 py-1 px-6 text-center  hover:border-neutral-400 hover:text-neutral-400"
                        onClick={() => {
                            alert('Not Available!')
                        }}
                    >
                        Minting
                    </div>
                </div>
            </div>
        </AnimatePresence>
    );
};


export default (Main);
