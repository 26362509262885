import { Outlet } from 'react-router-dom';
import HeaderNoLogo from "@/components/HeaderNoLogo";
import Footer from "@/components/Footer";

const Layout = () => (
    <div className={"flex flex-col h-full justify-between"}>
        <HeaderNoLogo />
        <Outlet />
        <Footer />
    </div>
);

export default Layout;